#root,
body,
html,
.layout,
.RaLayout-root-1 {
  margin: 0;
  height: 100%;
  width: 100%;
  background: #fff;
}
